import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ACTIVE_SPRINT_STAGES,
  GET_ALL_ACTIVE_SPRINT_STAGES,
  DRAG_ISSUES_STAGES,
  GET_ALL_STAGE_ISSUES,
  ISSUE_DATA,
  DELETE_ISSUE,
  CREATE_ISSUES,
  ADD_COMMENTS,
  GET_COMMENTS,
  EDIT_COMMENTS,
  PROJECT_FILTER_DETAILS,
  GET_HISTORY,
  GET_ALL,
} from "services/endPoints";
import { privateRequest } from "services/privateRequest";
import toast from "react-hot-toast";

const initialState = {
  activeSprintList: [],
  loading: false,
  loader: false,
  dropDownData: { id: null, open: false },
  allActiveSprintList: [],
  allStageIssues: [],
  getIssuesContent: [],
  allcomments: [],
  projectFilterDetails: {},
  filterParams: null,
  AllActivitys: [],
  count: {},
  allHistory: [],
  filterDropDown: {
    open: false,
    data: null,
  },
  rightTableDropDown: {
    open: false,
    data: null,
  },
};

export const getProjectFilterDetails = createAsyncThunk(
  "entity/getProjectFilterDetails",
  async () => {
    try {
      const res = await privateRequest.get(PROJECT_FILTER_DETAILS);
      return { data: res?.data?.data };
    } catch (err) {
      throw err;
    }
  }
);

export const activeSprintStages = createAsyncThunk(
  "entity/activeSprintStages",
  async (params, { dispatch }) => {
    try {
      const res = await privateRequest.post(ACTIVE_SPRINT_STAGES, params);
      toast.success(res?.data.message);
      await dispatch(getAllActiveSprintStages({ projectId: params.projectId }));
      await dispatch(
        getAllStageIssues({
          data: {
            projectId: localStorage.getItem("projectId"),
          },
          params: params?.filterParams || {},
        })
      );
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const getAllActiveSprintStages = createAsyncThunk(
  "entity/getAllActiveSprintStages",
  async (params) => {
    try {
      const res = await privateRequest.get(
        `${GET_ALL_ACTIVE_SPRINT_STAGES}/${params.projectId}`
      );
      return { data: res?.data?.data || [] };
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);

export const getAllStageIssues = createAsyncThunk(
  "entity/getAllStageIssues",
  async ({ data, params = null }) => {
    try {
      const res = await privateRequest.get(
        `${GET_ALL_STAGE_ISSUES}/${data.projectId}`,
        { params }
      );
      return { data: res?.data?.data || [] };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const deleteActiveSprintStages = createAsyncThunk(
  "entity/deleteActiveSprintStages",
  async (params) => {
    try {
      const res = await privateRequest.delete(
        `${ACTIVE_SPRINT_STAGES}/${params.id}`
      );
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const editColumnHeader = createAsyncThunk(
  "entity/editColumnHeader",
  async (params, { dispatch }) => {
    try {
      const res = await privateRequest.patch(
        `${ACTIVE_SPRINT_STAGES}/${params.id}`,
        null,
        { params: { name: params.name } }
      );
      await dispatch(
        getAllActiveSprintStages({
          projectId: localStorage?.getItem("projectId"),
        })
      );
      await dispatch(
        getAllStageIssues({
          data: {
            projectId: localStorage.getItem("projectId"),
          },
          params: params?.filterParams || {},
        })
      );
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const dragStageTicket = createAsyncThunk(
  "entity/dragStageTicket",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const res = await privateRequest.patch(
        `${DRAG_ISSUES_STAGES}/${params.issueId}`,
        null,
        { params: { stageId: +params.stageId } }
      );
      await dispatch(
        getAllStageIssues({
          data: { projectId: localStorage.getItem("projectId") },
          params: params?.filterParams || {},
        })
      );
      await dispatch(
        getAllActiveSprintStages({
          projectId: localStorage.getItem("projectId"),
        })
      );
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      rejectWithValue(error);
      throw error;
    }
  }
);

export const dragColumnHeader = createAsyncThunk(
  "entity/dragColumnHeader",
  async (params, { dispatch }) => {
    try {
      const res = await privateRequest.patch(
        ACTIVE_SPRINT_STAGES,
        params.updatedColumns
      );
      await dispatch(
        getAllActiveSprintStages({
          projectId: localStorage?.getItem("projectId"),
        })
      );
      await dispatch(
        getAllStageIssues({
          data: {
            projectId: localStorage.getItem("projectId"),
          },
          params: params?.filterParams,
        })
      );
      toast.success(res?.data?.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const editIssueById = createAsyncThunk(
  "entity/editIssueById",
  async (params) => {
    const formData = new FormData();
    formData.append("projectId", params.projectId);
    formData.append("description", params.description);
    if (!params.detailsPage) {
      formData.append("summary", params.summary);
      formData.append("issueType", params.issueType);
      formData.append("statusId", params.statusId);
      formData.append("assignedUserId", params.assignedUserId);
      formData.append("priority", params.priority);
      formData.append("sprintId", params.sprintId);
      formData.append("storyPoint", params.storyPoint);
    }
    try {
      const res = await privateRequest.patch(
        `${CREATE_ISSUES}/${params.id}`,
        formData
      );
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const deleteIssuesById = createAsyncThunk(
  "entity/deleteIssuesById",
  async (params) => {
    try {
      const res = await privateRequest.delete(`${DELETE_ISSUE}/${params.id}`);
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const getIssuesContentById = createAsyncThunk(
  "entity/getIssuesContentById",
  async (params) => {
    try {
      const res = await privateRequest.get(`${ISSUE_DATA}/${params.projectId}`);
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const deletStage = createAsyncThunk(
  "entity/deletStage",
  async (params, { dispatch }) => {
    try {
      const res = await privateRequest.delete(
        `${ACTIVE_SPRINT_STAGES}/${params.id}?newStageId=${params.newStageId}`
      );
      await dispatch(
        getAllActiveSprintStages({
          projectId: localStorage.getItem("projectId"),
        })
      );
      await dispatch(
        getAllStageIssues({
          data: {
            projectId: localStorage.getItem("projectId"),
          },
          params: params?.filterParams || {},
        })
      );
      toast.success(res?.data.message);
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const postComment = createAsyncThunk(
  "entity/postComment",
  async (params, { dispatch }) => {
    console.log("params", params);
    try {
      const res = await privateRequest.post(ADD_COMMENTS, params);
      toast.success(res?.data.message);
      await dispatch(getComment({ id: params.issueId }));
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const getComment = createAsyncThunk(
  "entity/getComment",
  async (params) => {
    try {
      const res = await privateRequest.get(`${GET_COMMENTS}/${params?.id}`);
      return { data: res?.data?.data || [] };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const editCommentById = createAsyncThunk(
  "entity/editCommentById",
  async ({ payload, id, issueId }, { dispatch }) => {
    try {
      const res = await privateRequest.put(`${EDIT_COMMENTS}/${id}`, payload);
      toast.success(res.data.message);
      await dispatch(getComment({ id: issueId }));
      return { data: res?.data };
    } catch (error) {
      console.error("Error editing comment:", error);
      throw error;
    }
  }
);

export const getHistory = createAsyncThunk(
  "getHistory",
  async ({ issueId }) => {
    try {
      const res = await privateRequest.get(`${GET_HISTORY}/${issueId}`);
      return { data: res?.data?.data || [] };
    } catch (err) {
      throw err;
    }
  }
);

export const getAllActivitys = createAsyncThunk(
  "getAllActivitys",
  async ({ issueId, pageNumber, pageSize, type ,sortOrder}) => {
    try {
      const res = await privateRequest.get(
        `${GET_ALL}/${issueId}/activity/${type}?page-no=${pageNumber}&page-size=${pageSize}&order-by=${sortOrder}`
      );
      return {
        result: res?.data?.data?.data || [],
        count: res?.data?.data?.count,
      };
    } catch (err) {
      throw err;
    }
  }
);

export const deleteComentById = createAsyncThunk(
  "entity/deleteComentById",
  async ({ params, id, issueId }, { dispatch }) => {
    try {
      const res = await privateRequest.delete(`${EDIT_COMMENTS}/${id}`, {
        data: params,
      });
      toast.success(res?.data.message);
      await dispatch(getComment({ id: issueId }));
      return { data: res?.data };
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

const activeSprintSlice = createSlice({
  name: "activeSprint",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDropDownData: (state, action) => {
      state.dropDownData = action.payload;
    },
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
    setFilterDropDown: (state, action) => {
      state.filterDropDown = action.payload;
    },
    setRightTableDropDown: (state, action) => {
      state.rightTableDropDown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(activeSprintStages.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(activeSprintStages.fulfilled, (state, action) => {
        state.activeSprintList = action.payload.data;
        state.loader = false;
        state.loading = false;
      })
      .addCase(activeSprintStages.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getAllActiveSprintStages.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getAllActiveSprintStages.fulfilled, (state, action) => {
        state.allActiveSprintList = action.payload.data;
        state.loader = false;
        state.loading = false;
      })
      .addCase(getAllActiveSprintStages.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getProjectFilterDetails.fulfilled, (state, action) => {
        state.projectFilterDetails = action?.payload?.data;
      })
      .addCase(editColumnHeader.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(editColumnHeader.fulfilled, (state, action) => {
        state.loader = false;
        state.loading = false;
      })
      .addCase(editColumnHeader.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getAllStageIssues.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getAllStageIssues.fulfilled, (state, action) => {
        state.allStageIssues = action.payload.data;
        state.loader = false;
        state.loading = false;
      })
      .addCase(getAllStageIssues.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(editIssueById.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(editIssueById.fulfilled, (state, action) => {
        // state.allStageIssues = action.payload.data
        state.loader = false;
        state.loading = false;
      })
      .addCase(editIssueById.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(deleteIssuesById.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(deleteIssuesById.fulfilled, (state, action) => {
        state.loader = false;
        state.loading = false;
      })
      .addCase(deleteIssuesById.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getIssuesContentById.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getIssuesContentById.fulfilled, (state, action) => {
        state.getIssuesContent = action.payload.data;
        state.loader = false;
        state.loading = false;
      })
      .addCase(getIssuesContentById.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(deletStage.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(deletStage.fulfilled, (state, action) => {
        state.loader = false;
        state.loading = false;
      })
      .addCase(deletStage.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(postComment.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.loader = false;
        state.loading = false;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getComment.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(getComment.fulfilled, (state, action) => {
        state.allcomments = action.payload?.data;
        state.loader = false;
        state.loading = false;
      })
      .addCase(getComment.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(getAllActivitys.fulfilled, (state, action) => {
        state.AllActivitys = action.payload?.result;
        state.count = action.payload?.count;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.allHistory = action.payload?.data;
      })
      .addCase(editCommentById.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(editCommentById.fulfilled, (state, action) => {
        state.loader = false;
        state.loading = false;
      })
      .addCase(editCommentById.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(deleteComentById.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(deleteComentById.fulfilled, (state, action) => {
        state.loader = false;
        state.loading = false;
      })
      .addCase(deleteComentById.rejected, (state, action) => {
        state.loader = false;
      });
  },
});

export default activeSprintSlice.reducer;

export const {
  setLoading,
  setDropDownData,
  setFilterParams,
  setFilterDropDown,
  setRightTableDropDown,
} = activeSprintSlice.actions;
